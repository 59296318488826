import DashboardLayout from '@/pages/Layout/DashboardLayout.vue';
import AuthLayout from '@/pages/Layout/AuthLayout.vue';

// Dashboard pages
import Dashboard from '@/pages/Dashboard/Dashboard.vue';

// Pages
const User = () => import('@/pages/Dashboard/Pages/UserProfile.vue');
const Pricing = () => import('@/pages/Dashboard/Pages/Pricing.vue');
const TimeLine = () => import('@/pages/Dashboard/Pages/TimeLinePage.vue');
const RtlSupport = () => import('@/pages/Dashboard/Pages/RtlSupport.vue');
const Login = () => import('@/pages/Login.vue');
const Logout = () => import('@/pages/Logout.vue');
const Register = () => import('@/pages/Dashboard/Pages/Register.vue');
const Lock = () => import('@/pages/Dashboard/Pages/Lock.vue');

// Components pages
const Buttons = () => import('@/pages/Dashboard/Components/Buttons.vue');
const GridSystem = () => import('@/pages/Dashboard/Components/GridSystem.vue');
const Panels = () => import('@/pages/Dashboard/Components/Panels.vue');
const SweetAlert = () => import('@/pages/Dashboard/Components/SweetAlert.vue');
const Notifications = () =>
    import('@/pages/Dashboard/Components/Notifications.vue');
const Icons = () => import('@/pages/Dashboard/Components/Icons.vue');
const Typography = () => import('@/pages/Dashboard/Components/Typography.vue');

// Forms pages
const RegularForms = () => import('@/pages/Dashboard/Forms/RegularForms.vue');
const ExtendedForms = () => import('@/pages/Dashboard/Forms/ExtendedForms.vue');
const ValidationForms = () =>
    import('@/pages/Dashboard/Forms/ValidationForms.vue');
const Wizard = () => import('@/pages/Dashboard/Forms/Wizard.vue');

// TableList pages
const RegularTables = () =>
    import('@/pages/Dashboard/Tables/RegularTables.vue');
const ExtendedTables = () =>
    import('@/pages/Dashboard/Tables/ExtendedTables.vue');
const PaginatedTables = () =>
    import('@/pages/Dashboard/Tables/PaginatedTables.vue');

// Maps pages
const GoogleMaps = () => import('@/pages/Dashboard/Maps/GoogleMaps.vue');
const FullScreenMap = () => import('@/pages/Dashboard/Maps/FullScreenMap.vue');
const VectorMaps = () => import('@/pages/Dashboard/Maps/VectorMaps.vue');

// Calendar
const Calendar = () => import('@/pages/Dashboard/Calendar.vue');
// Charts
const Charts = () => import('@/pages/Dashboard/Charts.vue');
import Widgets from '@/pages/Dashboard/Widgets.vue';

let componentsMenu = {
    path: '/components',
    component: DashboardLayout,
    redirect: '/components/buttons',
    name: 'Components',
    children: [
        {
            path: 'buttons',
            name: 'Buttons',
            components: { default: Buttons },
        },
        {
            path: 'grid-system',
            name: 'Grid System',
            components: { default: GridSystem },
        },
        {
            path: 'panels',
            name: 'Panels',
            components: { default: Panels },
        },
        {
            path: 'sweet-alert',
            name: 'Sweet Alert',
            components: { default: SweetAlert },
        },
        {
            path: 'notifications',
            name: 'Notifications',
            components: { default: Notifications },
        },
        {
            path: 'icons',
            name: 'Icons',
            components: { default: Icons },
        },
        {
            path: 'typography',
            name: 'Typography',
            components: { default: Typography },
        },
    ],
};
let formsMenu = {
    path: '/forms',
    component: DashboardLayout,
    redirect: '/forms/regular',
    name: 'Forms',
    children: [
        {
            path: 'regular',
            name: 'Regular Forms',
            components: { default: RegularForms },
        },
        {
            path: 'extended',
            name: 'Extended Forms',
            components: { default: ExtendedForms },
        },
        {
            path: 'validation',
            name: 'Validation Forms',
            components: { default: ValidationForms },
        },
        {
            path: 'wizard',
            name: 'Wizard',
            components: { default: Wizard },
        },
    ],
};

let tablesMenu = {
    path: '/table-list',
    component: DashboardLayout,
    redirect: '/table-list/regular',
    name: 'Tables',
    children: [
        {
            path: 'regular',
            name: 'Regular Tables',
            components: { default: RegularTables },
        },
        {
            path: 'extended',
            name: 'Extended Tables',
            components: { default: ExtendedTables },
        },
        {
            path: 'paginated',
            name: 'Pagianted Tables',
            components: { default: PaginatedTables },
        },
    ],
};

let mapsMenu = {
    path: '/maps',
    component: DashboardLayout,
    name: 'Maps',
    redirect: '/maps/google',
    children: [
        {
            path: 'google',
            name: 'Google Maps',
            components: { default: GoogleMaps },
        },
        {
            path: 'full-screen',
            name: 'Full Screen Map',
            meta: {
                hideContent: true,
                hideFooter: true,
                navbarAbsolute: true,
            },
            components: { default: FullScreenMap },
        },
        {
            path: 'vector-map',
            name: 'Vector Map',
            components: { default: VectorMaps },
        },
    ],
};

let pagesMenu = {
    path: '/pages',
    component: DashboardLayout,
    name: 'Pages',
    redirect: '/pages/user',
    children: [
        {
            path: 'user',
            name: 'User Page',
            components: { default: User },
        },
        {
            path: 'timeline',
            name: 'Timeline Page',
            components: { default: TimeLine },
        },
        {
            path: 'rtl',
            name: 'وحة القيادة',
            meta: {
                rtlActive: true,
            },
            components: { default: RtlSupport },
        },
    ],
};

let authPages = {
    path: '/',
    component: AuthLayout,
    name: 'Authentication',
    children: [
        {
            path: '/login',
            name: 'ログイン',
            component: Login,
        },
        {
            path: '/logout',
            name: 'ログアウト',
            component: Logout,
        },
        {
            path: '/register',
            name: 'Register',
            component: Register,
        },
        {
            path: '/pricing',
            name: 'Pricing',
            component: Pricing,
        },
        {
            path: '/lock',
            name: 'Lock',
            component: Lock,
        },
    ],
};

/**
 * プロフィール
 */
const userProfile = {
    path: '/',
    component: DashboardLayout,
    name: 'プロフィール',
    children: [
        {
            path: '/profile',
            name: 'プロフィール編集',
            component: () => import('@/pages/Profile.vue'),
        },
    ],
};

/**
 * スタッフ
 */
const usersPages = {
    path: '/users',
    component: DashboardLayout,
    name: 'スタッフ',
    redirect: '/users/index',
    children: [
        {
            path: 'index',
            name: 'スタッフの一覧',
            component: () => import('@/pages/Users/Index.vue'),
        },
        {
            path: 'add',
            name: 'スタッフの新規追加',
            component: () => import('@/pages/Users/Add.vue'),
        },
        {
            path: 'edit/:id',
            name: 'スタッフの編集',
            component: () => import('@/pages/Users/Edit.vue'),
        },
    ],
};
/**
 *  得意先
 */
const clientsPages = {
    path: '/clients',
    component: DashboardLayout,
    name: '得意先',
    redirect: '/clients/index',
    children: [
        {
            path: 'index',
            name: ' 得意先の一覧',
            component: () => import('@/pages/Clients/Index.vue'),
        },
        {
            path: 'add',
            name: '得意先の新規追加',
            component: () => import('@/pages/Clients/Add.vue'),
        },
        {
            path: 'edit/:id',
            name: '得意先の編集',
            component: () => import('@/pages/Clients/Edit.vue'),
        },
    ],
};

/**
 *  案件
 */
const projectsPages = {
    path: '/projects',
    component: DashboardLayout,
    name: '案件',
    redirect: '/projects/index',
    children: [
        {
            path: 'index',
            name: ' 案件の一覧',
            component: () => import('@/pages/Projects/Index.vue'),
        },
        {
            path: 'add',
            name: '案件の新規追加',
            component: () => import('@/pages/Projects/Add.vue'),
        },
        {
            path: 'edit/:id',
            name: '案件の編集',
            component: () => import('@/pages/Projects/Edit.vue'),
        },
    ],
};
/**
 *  作業トラッキング
 */
const trackerPages = {
    path: '/tracker',
    component: DashboardLayout,
    name: 'トラッカー',
    redirect: '/tracker/index',
    children: [
        {
            path: 'index',
            name: '作業トラッキング',
            component: () => import('@/pages/Tracker/Index.vue'),
        },
    ],
};
/**
 *  日報
 */
const reportsPages = {
    path: '/reports',
    component: DashboardLayout,
    name: '日報',
    redirect: '/Reports/index',
    children: [
        {
            path: 'index',
            name: '日報の一覧',
            component: () => import('@/pages/Reports/Index.vue'),
        },
        {
            path: 'edit/:id',
            name: '日報の編集',
            component: () => import('@/pages/Reports/Edit.vue'),
        },
    ],
};
const routes = [
    {
        path: '/',
        redirect: '/reports',
        name: '日報リスト',
    },
    componentsMenu,
    formsMenu,
    tablesMenu,
    mapsMenu,
    pagesMenu,
    authPages,
    {
        path: '/',
        component: DashboardLayout,
        children: [
            {
                path: 'dashboard',
                name: 'Dashboard',
                components: { default: Dashboard },
            },
            {
                path: 'calendar',
                name: 'Calendar',
                components: { default: Calendar },
            },
            {
                path: 'charts',
                name: 'Charts',
                components: { default: Charts },
            },
            {
                path: 'widgets',
                name: 'Widgets',
                components: { default: Widgets },
            },
        ],
    },
    usersPages,
    userProfile,
    clientsPages,
    projectsPages,
    trackerPages,
    reportsPages,
];

export default routes;
