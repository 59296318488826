import Vue from 'vue';
import VueRouter from 'vue-router';
import DashboardPlugin from './material-dashboard';

// Plugins
import App from './App.vue';
import Chartist from 'chartist';
import Moment from 'vue-moment';
import MomentDurationFormatSetup from 'moment-duration-format';
import Datetime from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';

// 追加CSS
import './assets/scss/global.scss';

// router setup
import routes from './routes/routes';

// plugin setup
Vue.use(VueRouter);
Vue.use(DashboardPlugin);
Vue.use(Moment);
Vue.use(Datetime);

MomentDurationFormatSetup(Vue.moment);

// configure router
const router = new VueRouter({
    routes, // short for routes: routes
    linkExactActiveClass: 'nav-item active',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
});

//beforeEach
router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');

    if (authRequired && !loggedIn) {
        return next('/login');
    }
    next();
});

// global library setup
Object.defineProperty(Vue.prototype, '$Chartist', {
    get() {
        return this.$root.Chartist;
    },
});

/* eslint-disable no-new */
new Vue({
    el: '#app',
    data: {
        Chartist: Chartist,
    },
    render: h => h(App),
    router,
});

//
