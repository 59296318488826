<template>
    <div
        class="wrapper"
        :class="[{'nav-open': $sidebar.showSidebar}, {'rtl': $route.meta.rtlActive}]"
    >
        <notifications />
        <side-bar>
            <!--            <user-menu></user-menu>-->
            <!--            <mobile-menu></mobile-menu>-->
            <template slot="links">
                <div class="sidebar-after-line">
                    <sidebar-item :link="{name: loginUser.name, icon: 'face'}">
                        <sidebar-item :link="{name: '編集', icon: 'edit', path: '/profile'}" />
                        <sidebar-item :link="{name: 'ログアウト', icon: 'exit_to_app',path: '/logout'}" />
                    </sidebar-item>
                </div>

                <sidebar-item
                    :link="{name: '作業トラッキング', icon: 'timer', path: '/tracker/index'}"
                />
                <sidebar-item
                    :link="{name: '日報', icon: 'assignment', path: '/reports/index'}"
                />
                <sidebar-item
                    :link="{name: 'スタッフ', icon: 'person', path: '/users/index'}"
                />
                <sidebar-item
                    :link="{name: '得意先', icon: 'supervised_user_circle', path: '/clients/index'}"
                />
                <sidebar-item
                    :link="{name: '案件', icon: 'all_inbox', path: '/projects/index'}"
                />

                <!-- Template Menu-->
                <sidebar-item :link="{name: 'テンプレート', icon: 'image'}">
                    <sidebar-item :link="{name: 'Dashboard', icon: 'dashboard', path: '/dashboard'}" />

                    <sidebar-item :link="{name: 'Pages', icon: 'image'}">
                        <sidebar-item :link="{name: 'Pricing', path: '/pricing'}" />
                        <sidebar-item :link="{name: 'RTL Support', path: '/pages/rtl'}" />
                        <sidebar-item :link="{name: 'Timeline', path: '/pages/timeline'}" />
                        <sidebar-item :link="{name: 'Login', path: '/login'}" />
                        <sidebar-item :link="{name: 'Register', path: '/register'}" />
                        <sidebar-item :link="{name: 'Lock Screen', path: '/lock'}" />
                        <sidebar-item :link="{name: 'User Profile', path: '/pages/user'}" />
                    </sidebar-item>

                    <sidebar-item :link="{name: 'Components', icon: 'apps'}">
                        <sidebar-item :link="{name: 'Buttons', path: '/components/buttons'}" />
                        <sidebar-item :link="{name: 'Grid System', path: '/components/grid-system'}" />
                        <sidebar-item :link="{name: 'Panels', path: '/components/panels'}" />
                        <sidebar-item :link="{name: 'Sweet Alert', path: '/components/sweet-alert'}" />
                        <sidebar-item :link="{name: 'Notifications', path: '/components/notifications'}" />
                        <sidebar-item :link="{name: 'Icons', path: '/components/icons'}" />
                        <sidebar-item :link="{name: 'Typography', path: '/components/typography'}" />
                    </sidebar-item>

                    <sidebar-item :link="{name: 'Forms', icon: 'content_paste'}">
                        <sidebar-item :link="{name: 'Regular Forms', path: '/forms/regular'}" />
                        <sidebar-item :link="{name: 'Extended Forms', path: '/forms/extended'}" />
                        <sidebar-item :link="{name: 'Validation Forms', path: '/forms/validation'}" />
                        <sidebar-item :link="{name: 'Wizard', path: '/forms/wizard'}" />
                    </sidebar-item>

                    <sidebar-item :link="{name: 'Tables', icon: 'grid_on'}">
                        <sidebar-item :link="{name: 'Regular Tables', path: '/table-list/regular'}" />
                        <sidebar-item :link="{name: 'Extended Tables', path: '/table-list/extended'}" />
                        <sidebar-item :link="{name: 'Paginated Tables', path: '/table-list/paginated'}" />
                    </sidebar-item>


                    <sidebar-item :link="{name: 'Maps', icon: 'place'}">
                        <sidebar-item :link="{name: 'Google Maps', path: '/maps/google'}" />
                        <sidebar-item :link="{name: 'Full Screen Maps', path: '/maps/full-screen'}" />
                        <sidebar-item :link="{name: 'Vector Maps', path: '/maps/vector-map'}" />
                    </sidebar-item>

                    <sidebar-item

                        :link="{name: 'Widgets', icon: 'widgets', path: '/widgets'}"
                    />

                    <sidebar-item

                        :link="{name: 'Charts', icon: 'timeline', path: '/charts'}"
                    />
                    <sidebar-item

                        :link="{name: 'Calendar', icon: 'date_range', path: '/calendar'}"
                    />
                </sidebar-item>
            </template>
        </side-bar>
        <div class="main-panel">
            <top-navbar />

            <div
                :class="{content: !$route.meta.hideContent}"
                @click="toggleSidebar"
            >
                <zoom-center-transition
                    :duration="200"
                    mode="out-in"
                >
                    <!-- your content here -->
                    <router-view />
                </zoom-center-transition>
            </div>
            <content-footer v-if="!$route.meta.hideFooter" />
        </div>
    </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
    if (hasElement(className)) {
        new PerfectScrollbar(`.${className}`);
    } else {
        // try to init it later in case this component is loaded async
        setTimeout(() => {
            initScrollbar(className);
        }, 100);
    }
}

import TopNavbar from './TopNavbar.vue';
import ContentFooter from './ContentFooter.vue';
// import MobileMenu from "./Extra/MobileMenu.vue";
// import UserMenu from "./Extra/UserMenu.vue";
import { ZoomCenterTransition } from 'vue2-transitions';

export default {
    components: {
        TopNavbar,
        ContentFooter,
        // MobileMenu,
        // UserMenu,
        ZoomCenterTransition,
    },
    data: function() {
        return {
            loginUser: {},
        };
    },
    created: function() {
        this.loginUser = JSON.parse(localStorage.getItem('user'));
    },
    mounted() {
        let docClasses = document.body.classList;
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
            // if we are on windows OS we activate the perfectScrollbar function
            initScrollbar('sidebar');
            initScrollbar('sidebar-wrapper');
            initScrollbar('main-panel');

            docClasses.add('perfect-scrollbar-on');
        } else {
            docClasses.add('perfect-scrollbar-off');
        }
    },
    methods: {
        toggleSidebar() {
            if (this.$sidebar.showSidebar) {
                this.$sidebar.displaySidebar(false);
            }
        },
    },
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
    from {
        opacity: 0;
        transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
    to {
        opacity: 1;
    }
}

.main-panel .zoomIn {
    animation-name: zoomIn95;
}

@keyframes zoomOut95 {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
}

.main-panel .zoomOut {
    animation-name: zoomOut95;
}

.sidebar-after-line {
    padding-top: 5px;
    padding-bottom: 20px;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 15px;
        height: 1px;
        width: calc(100% - 30px);
        background-color: rgba(180, 180, 180, 0.3);
    }
}
</style>
