<template>
    <div
        class="sidebar"
        :data-color="activeColor"
        :data-image="backgroundImage"
        :data-background-color="backgroundColor"
        :style="sidebarStyle"
    >
        <div class="logo">
            <a
                class="simple-text logo-mini"
                target="_blank"
            >
                <div class="logo-img--square">
                    <img :src="logo">
                </div>
            </a>
            <a
                class="simple-text logo-normal"
                target="_blank"
            >
                <template v-if="$route.meta.rtlActive">{{ rtlTitle }}</template>
                <template v-else>{{ title }}</template>
            </a>
            <div class="navbar-minimize">
                <md-button
                    id="minimizeSidebar"
                    class="md-round md-just-icon md-transparent"
                    @click="minimizeSidebar"
                >
                    <i class="material-icons text_align-center visible-on-sidebar-regular">more_vert</i>
                    <i class="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i>
                </md-button>
            </div>
        </div>
        <div
            ref="sidebarScrollArea"
            class="sidebar-wrapper"
        >
            <slot />
            <md-list class="nav">
                <slot name="links">
                    <sidebar-item
                        v-for="(link, index) in sidebarLinks"
                        :key="link.name + index"
                        :link="link"
                    >
                        <sidebar-item
                            v-for="(subLink, idx) in link.children"
                            :key="subLink.name + idx"
                            :link="subLink"
                        />
                    </sidebar-item>
                </slot>
            </md-list>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Sidebar',
    provide() {
        return {
            autoClose: this.autoClose,
        };
    },
    props: {
        title: {
            type: String,
            default: '日報システム',
        },
        rtlTitle: {
            type: String,
            default: 'توقيت الإبداعية',
        },
        activeColor: {
            type: String,
            default: 'green',
            validator: value => {
                let acceptedValues = [
                    '',
                    'primary',
                    'azure',
                    'green',
                    'orange',
                    'danger',
                    'rose',
                ];
                return acceptedValues.indexOf(value) !== -1;
            },
        },
        backgroundImage: {
            type: String,
            default: './img/sidebar-2.jpg',
        },
        backgroundColor: {
            type: String,
            default: 'black',
            validator: value => {
                let acceptedValues = ['', 'black', 'white', 'red'];
                return acceptedValues.indexOf(value) !== -1;
            },
        },
        logo: {
            type: String,
            default: './img/elastic-logo.png',
        },
        sidebarLinks: {
            type: Array,
            default: () => [],
        },
        autoClose: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        sidebarStyle() {
            return {
                backgroundImage: `url(${this.backgroundImage})`,
            };
        },
    },
    beforeDestroy() {
        if (this.$sidebar.showSidebar) {
            this.$sidebar.showSidebar = false;
        }
    },
    methods: {
        minimizeSidebar() {
            if (this.$sidebar) {
                this.$sidebar.toggleMinimize();
            }
        },
    },
};
</script>
<style>
@media (min-width: 992px) {
    .navbar-search-form-mobile,
    .nav-mobile-menu {
        display: none;
    }
}
</style>

<style scoped>
.logo-img--square {
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 34px;
    width: 34px;
    background: #fff;
    border-radius: 15%;
    text-align: center;
    overflow: hidden;
}

.logo-img--square img {
    width: 90%;
    vertical-align: unset;
    position: relative;
}
</style>
