<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div
                    v-click-outside="closeModal"
                    class="modal-container"
                >
                    <div class="modal-header">
                        <slot name="header" />
                    </div>

                    <div class="modal-body text-center">
                        <slot name="body" />
                    </div>

                    <div class="modal-footer">
                        <slot name="footer" />
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    methods: {
        closeModal: function() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss">
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
</style>
